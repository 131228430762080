import classNames from 'classnames';
import React from 'react';
import { oneOf, number, string } from 'prop-types';
import styles from '../../styles/outcomeEffectBox.module.styl';

const OutcomeEffectBox = ({
  absDenominator,
  absEffect,
  childrenOrder,
  effectSize,
  fewerMore,
  type,
}) => (
  <div className={classNames(styles[`${type}Box`], styles[`${childrenOrder}Box`])}>
    <div>
      {absEffect && (
        <div className={styles.absEffect}>
          {absEffect} {fewerMore}
        </div>
      )}
    </div>
    <div>
      {effectSize && <div className={styles.effectSize}>{effectSize}</div>}
      {absDenominator && <div className={styles.absDenominator}>per {absDenominator}</div>}
    </div>
  </div>
);

OutcomeEffectBox.propTypes = {
  absDenominator: number,
  absEffect: number,
  childrenOrder: oneOf(['left', 'right']),
  effectSize: number,
  fewerMore: string,
  type: oneOf(['white', 'blue', 'violet']),
};

OutcomeEffectBox.defaultProps = {
  absDenominator: null,
  absEffect: null,
  childrenOrder: 'left',
  effectSize: null,
  fewerMore: null,
  type: 'white',
};

export default OutcomeEffectBox;
