import React from 'react';
import { string } from 'prop-types';
import styles from '../../styles/components.module.styl';

const HeaderContentBox = ({ title, content }) => (
  <div className={styles.contentBoxWithHeader}>
    <h2>{title}</h2>
    <div className={styles.contentBoxWithHeaderContent}>{content}</div>
  </div>
);

HeaderContentBox.propTypes = {
  title: string.isRequired,
  content: string,
};

HeaderContentBox.defaultProps = {
  content: '',
};

export default HeaderContentBox;
