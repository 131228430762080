import React from "react"
import { string } from "prop-types"
import TextContent from "../common/TextContent"
import styles from "../../styles/outcomeTable.module.styl"

const NarrativeOutcomeTableRow = ({ outcome }) => {
  return (
    <div className={styles.outcomeTableRow}>
      <div className={styles.narrativeOutcomeRow}>
        <h4 className={styles.narrativeOutcomeName}>{outcome.get("name")}</h4>
        <div className={styles.narrativeOutcomeContent}>
          <TextContent
            className={styles.narrativeOutcomeText}
            content={outcome.get("narrativeText")}
          />
        </div>
        <div
          className={styles.narrativeOutcomeCertainty}
          dangerouslySetInnerHTML={{
            __html: outcome.get("certainty")
              ? `${outcome.get("certainty")}<br>certainty`
              : "-",
          }}
        />
      </div>
    </div>
  )
}

NarrativeOutcomeTableRow.propTypes = {
  intervention: string.isRequired,
}

export default NarrativeOutcomeTableRow
