import React from 'react';
import { string } from 'prop-types';
import ContentBox from '../common/ContentBox';
import TextContent from '../common/TextContent';

import styles from '../../styles/components.module.styl';

const BenefitsAndHarms = ({ title, harms, benefits }) => (
  <div className={styles.benefitsAndHarms}>
    <ContentBox>
      <div className={styles.benefitsAndHarmsTitle}>
        <h4>{title}</h4>
      </div>
      <div className={styles.benefitsAndHarmsContent}>
        <div className={styles.benefitsAndHarmsText}>
          <h2>Benefits</h2>
          <div className={styles.benefitsAndHarmsColumn}>
            <TextContent content={benefits} />
          </div>
        </div>
        <div className={styles.benefitsAndHarmsSeparator} />
        <div className={styles.benefitsAndHarmsText}>
          <h2>Harms</h2>
          <div className={styles.benefitsAndHarmsColumn}>
            <TextContent content={harms} />
          </div>
        </div>
      </div>
    </ContentBox>
  </div>
);

BenefitsAndHarms.propTypes = {
  title: string.isRequired,
  harms: string.isRequired,
  benefits: string.isRequired,
};

export default BenefitsAndHarms;
