import React from "react"
import _ from "underscore"
import { number, string } from "prop-types"
import { normalizeValue } from "../../lib/utils"
import Scale from "./Scale"
import textStyle from "../../styles/texts.module.styl"
import styles from "../../styles/outcomesScale.module.styl"

const markerText = (effectSize, absDenominator, treatment) => (
  <span>
    <span className={textStyle.bold}>{effectSize}</span>
    {` people per ${absDenominator} ${treatment}`}
  </span>
)

const OutcomeScale = ({
  scaleFrom,
  scaleTo,
  stepBy,
  absDenominator,
  outcome,
  intervention,
  comparison,
}) => {
  const scaleRange = _.range(scaleFrom, scaleTo + 1, stepBy)
  return (
    <div>
      <Scale
        className={styles.scaleViolet}
        value={normalizeValue(
          outcome.get("interventionAbsCount"),
          scaleFrom,
          scaleTo
        )}
        markerPosition="top"
        markerText={markerText(
          outcome.get("interventionAbsCount"),
          absDenominator,
          intervention
        )}
        markerSubText={null}
      />
      <div className={styles.scaleRange}>
        {scaleRange.map(scaleValue => (
          <div key={scaleValue} className={styles.scaleRangeValue}>
            {scaleValue}
          </div>
        ))}
      </div>
      <Scale
        className={styles.scaleBlue}
        value={normalizeValue(
          outcome.get("controlAbsCount"),
          scaleFrom,
          scaleTo
        )}
        markerPosition="bottom"
        markerText={markerText(
          outcome.get("controlAbsCount"),
          absDenominator,
          comparison
        )}
        markerSubText={null}
      />
    </div>
  )
}

OutcomeScale.propTypes = {
  scaleFrom: number,
  scaleTo: number,
  stepBy: number,
  absDenominator: number,
  intervention: string.isRequired,
  comparison: string.isRequired,
}

OutcomeScale.defaultProps = {
  scaleFrom: 0,
  scaleTo: 1000,
  absDenominator: 1000,
  stepBy: 100,
}

export default OutcomeScale
