import Immutable from "immutable"
import React from "react"
import _ from "underscore"
import HeaderWithIcon from "../components/common/HeaderWithIcon"
import TextContent from "../components/common/TextContent"
import BenefitsAndHarms from "../components/comparison/BenefitsAndHarms"
import SubPageHeader from "../components/header/SubPageHeader"
import ArrowNavigation from "../components/navigation/ArrowNavigation"
import Layout from "../components/layout"
import OutcomesTable from "../components/outcomes/OutcomesTable"
import styles from "../styles/content.module.styl"
import textStyles from "../styles/texts.module.styl"

const Options = ({ location, data, pathContext }) => {
  const nodeData = data.decisionAidsJson
  const { pageId, groupId, subPage, group } = pathContext

  const prevLink = groupId ? `/${pageId}/${groupId}/facts` : `/${pageId}/facts`
  const nextLink = groupId
    ? `/${pageId}/${groupId}/summary`
    : `/${pageId}/summary`

  const benefits = group
    ? group.benefitsAndHarms.benefits
    : nodeData.benefitsAndHarms.benefits

  const harms = group
    ? group.benefitsAndHarms.harms
    : nodeData.benefitsAndHarms.harms

  return (
    <Layout>
      <SubPageHeader
        title={nodeData.title}
        group={group}
        hasGroups={!_.isEmpty(nodeData.groups)}
        location={location}
        logo={nodeData.logo}
        pageId={pageId}
        groupId={groupId}
        subPage={subPage}
      />
      <div className={styles.content}>
        <HeaderWithIcon title="Research results" type="research" />
        <TextContent content={nodeData.researchResults.content} />
        <h2 className={styles.mt40}>
          {nodeData.researchResults.outcomesComparison.title}
        </h2>
        <OutcomesTable
          happensTo={nodeData.researchResults.outcomesComparison.happensTo}
          intervention={
            nodeData.researchResults.outcomesComparison.intervention
          }
          comparison={nodeData.researchResults.outcomesComparison.comparison}
          absDenominator={
            nodeData.researchResults.outcomesComparison.absDenominator
          }
          outcomes={Immutable.fromJS(
            nodeData.researchResults.outcomesComparison.outcomes
          )}
          explanations={
            nodeData.researchResults.outcomesComparison.explanations
          }
        />
        <h2 className={textStyles.comparisonHeader}>Comparison</h2>

        <BenefitsAndHarms
          title={nodeData.researchResults.outcomesComparison.intervention}
          benefits={benefits}
          harms={harms}
        />

        <ArrowNavigation prevLink={prevLink} nextLink={nextLink} />
      </div>
    </Layout>
  )
}

export default Options

export const query = graphql`
  query OptionsQuery($pageId: String) {
    decisionAidsJson(pageId: { eq: $pageId }) {
      title
      logo
      benefitsAndHarms {
        benefits
        harms
      }
      researchResults {
        content
        outcomesComparison {
          title
          intervention
          comparison
          absDenominator
          happensTo
          outcomes {
            name
            interventionAbsCount
            controlAbsCount
            choose
            certainty
            whatHappens
            narrativeText
          }
        }
      }
    }
  }
`
