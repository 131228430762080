import React from "react"
import _ from "lodash"
import Collapsible from "../common/Collapsible"
import HeaderContentBox from "../common/HeaderContentBox"
import OutcomeEffectBox from "./OutcomeEffectBox"
import OutcomeScale from "./OutcomeScale"
import styles from "../../styles/outcomeTable.module.styl"

// TODO there is one problem with collapsible component
// long outcomes won't fit

const calcAbsEffect = (a, b) => a - b

const fewerMore = absEffect => (absEffect < 1 ? "fewer" : "more")

const OutcomeTableRow = ({
  intervention,
  comparison,
  outcome,
  absDenominator,
}) => {
  const checkedKey =
    outcome.get("choose") === "comparison"
      ? "controlAbsCount"
      : "interventionAbsCount"
  const notCheckedKey =
    outcome.get("choose") === "comparison"
      ? "interventionAbsCount"
      : "controlAbsCount"

  const absEffect = outcome.get("choose")
    ? calcAbsEffect(outcome.get(checkedKey), outcome.get(notCheckedKey))
    : 0

  return (
    <div className={styles.outcomeTableRow}>
      <Collapsible calculateHeight={true}>
        <div className={styles.outcomeRow}>
          <h4 className={styles.outcomeName}>{outcome.get("name")}</h4>
          <div className={styles.effects}>
            <div className={styles.interventionEffect}>
              <OutcomeEffectBox
                absEffect={
                  outcome.get("choose") === "intervention"
                    ? Math.abs(absEffect)
                    : null
                }
                fewerMore={fewerMore(absEffect)}
                effectSize={outcome.get("interventionAbsCount")}
                absDenominator={absDenominator}
                type={
                  outcome.get("choose") === "intervention" ? "violet" : "white"
                }
              />
            </div>
            <div className={styles.comparisonEffect}>
              <OutcomeEffectBox
                childrenOrder={"right"}
                absEffect={
                  outcome.get("choose") === "comparison"
                    ? Math.abs(absEffect)
                    : null
                }
                fewerMore={fewerMore(absEffect)}
                effectSize={outcome.get("controlAbsCount")}
                absDenominator={absDenominator}
                type={outcome.get("choose") === "comparison" ? "blue" : "white"}
              />
            </div>
          </div>
          <div
            className={styles.outcomeCertainty}
            dangerouslySetInnerHTML={{
              __html: outcome.get("certainty")
                ? `${outcome.get("certainty")}<br>certainty`
                : "-",
            }}
          />
        </div>
        <div className={styles.outcomeContent}>
          <OutcomeScale
            intervention={intervention}
            comparison={comparison}
            outcome={outcome}
            absDenominator={absDenominator}
            stepBy={absDenominator / 10}
            scaleTo={absDenominator}
          />
          {!_.isEmpty(outcome.get("whatHappens")) && (
            <div className={styles.whatHappens}>
              <HeaderContentBox
                title="What happens"
                content={outcome.get("whatHappens")}
              />
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  )
}

export default OutcomeTableRow
