import classNames from "classnames"
import React from "react"
import _ from "underscore"
import { node, string, oneOf } from "prop-types"
import styles from "../../styles/outcomesScale.module.styl"

const Scale = ({
  value,
  className,
  markerPosition,
  markerText,
  markerSubText,
}) => {
  let markerStyles = {}

  if (value >= 50) {
    markerStyles["right"] = `calc(100% - ${value}%)`
  } else {
    markerStyles["left"] = `calc(${value}%)`
  }

  return (
    <div className={classNames(styles.scaleContainer, styles[markerPosition])}>
      <div
        className={classNames(styles.marker, { [styles.flipped]: value > 50 })}
        style={markerStyles}
      >
        <div className={styles.markerIcon} />
        <div className={styles.markerText}>{markerText}</div>
        {markerSubText && (
          <div className={styles.markerSubText}>{markerSubText}</div>
        )}
      </div>
      <div className={className}>
        <div style={{ width: `${value}%` }} />
      </div>
    </div>
  )
}

Scale.propTypes = {
  className: string,
  markerText: node,
  markerSubText: node,
  markerPosition: oneOf(["top", "bottom"]).isRequired,
  value: oneOf(_.range(0, 101)),
}

Scale.defaultProps = {
  className: "",
  markerText: null,
  markerSubText: null,
  value: 0,
}

export default Scale
