import React from "react"
import _ from "underscore"
import ContentBox from "../common/ContentBox"
import OutcomeTableRow from "./OutcomeTableRow"
import NarrativeOutcomeTableRow from "./NarrativeOutcomeTableRow"
import styles from "../../styles/outcomeTable.module.styl"
import componentStyles from "../../styles/components.module.styl"

const OutcomesTable = ({
  intervention,
  comparison,
  happensTo,
  absDenominator,
  outcomes,
  explanations,
}) => {
  return (
    <ContentBox className={componentStyles.grayContentBox}>
      <div className={styles.header}>
        <div className={styles.outcomesTitle}>Outcomes</div>
        <div className={styles.intervention}>
          <h2>{intervention}</h2>
          <div className={styles.followUp}>{happensTo}</div>
        </div>
        <div className={styles.comparison}>
          <h2>{comparison}</h2>
          <div className={styles.followUp}>{happensTo}</div>
        </div>
        <div className={styles.certainty}>Certainty of the evidence</div>
      </div>
      <div className={styles.outcomes}>
        {outcomes.map((outcome, index) => {
          return outcome.get("narrativeText") ? (
            <NarrativeOutcomeTableRow
              key={index}
              intervention={intervention}
              outcome={outcome}
            />
          ) : (
            <OutcomeTableRow
              key={index}
              intervention={intervention}
              comparison={comparison}
              outcome={outcome}
              absDenominator={absDenominator}
            />
          )
        })}
      </div>
      <div className={styles.explanations}>
        {_(explanations).map((explanation, index) => (
          <div key={index}>
            {explanation.key}
            {explanation.value}
          </div>
        ))}
      </div>
    </ContentBox>
  )
}

export default OutcomesTable
