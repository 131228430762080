import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "../../styles/components.module.styl"

const ContentBox = ({ children, className, color }) => (
  <div className={classNames(className, styles[`${color}ContentBox`])}>
    {children}
  </div>
)

ContentBox.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["blue", "orange", "gray"]),
  children: PropTypes.node.isRequired,
}

ContentBox.defaultProps = {
  className: null,
  color: "blue",
}

export default ContentBox
